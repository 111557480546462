import { createSelector } from '@reduxjs/toolkit';
import { useQueryClient } from '@tanstack/react-query';
import { ReduxStore, useAppDispatch } from 'customer/store/configureStore';
import { useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { ValidationErrors } from 'types/validationTypes';
import actions from './actions';
import { AuthContext, LoginRequest } from './types';

interface UseLoginReturn {
  authContext?: AuthContext;
  isInvited: boolean;
  isLoginRequest: boolean;
  isLoginSuccess: boolean;
  isLoginFailure: ValidationErrors | undefined;
  isLogoutRequest: boolean;
  isLogoutSuccess: boolean;
  isLogoutFailure: ValidationErrors | undefined;
  isVerified: boolean;
  email: string | undefined;
  login: (data: LoginRequest) => Promise<AuthContext | void>;
  read: (userId: string) => Promise<AuthContext | void>;
  getAuthContext: () => Promise<AuthContext | void>;
}

const selectAuthContext = (state: ReduxStore) => state.iam?.login?.auth;

const selectIsLoginRequest = () =>
  useSelector(
    (state: ReduxStore) => !!state.iam?.login?.isLoginRequest,
    shallowEqual,
  );
const selectIsLoginSuccess = () =>
  useSelector(
    (state: ReduxStore) => !!state.iam?.login?.isLoginSuccess,
    shallowEqual,
  );
const selectIsLoginFailure = () =>
  useSelector(
    (state: ReduxStore) => state.iam?.login?.isLoginFailure,
    shallowEqual,
  );

const selectIsLogoutRequest = () =>
  useSelector(
    (state: ReduxStore) => !!state.iam?.login?.isLogoutRequest,
    shallowEqual,
  );
const selectIsLogoutSuccess = () =>
  useSelector(
    (state: ReduxStore) => !!state.iam?.login?.isLogoutSuccess,
    shallowEqual,
  );
const selectIsLogoutFailure = () =>
  useSelector(
    (state: ReduxStore) => state.iam?.login?.isLogoutFailure,
    shallowEqual,
  );

const selectLoginEmail = () =>
  useSelector(
    (state: ReduxStore) => state.iam?.login?.auth?.email,
    shallowEqual,
  );

const authContextSelector = createSelector(selectAuthContext, (ctx) => ctx);

const useLogin = (): UseLoginReturn => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const auth = useSelector(authContextSelector, shallowEqual);
  const isLoginRequest = selectIsLoginRequest();
  const isLoginSuccess = selectIsLoginSuccess();
  const isLoginFailure = selectIsLoginFailure();
  const isLogoutRequest = selectIsLogoutRequest();
  const isLogoutSuccess = selectIsLogoutSuccess();
  const isLogoutFailure = selectIsLogoutFailure();
  const email = selectLoginEmail();

  return {
    authContext: auth,
    isVerified: !!auth?.auth?.verified,
    isInvited: !!auth?.auth?.invited,
    isLoginRequest,
    isLoginSuccess,
    isLoginFailure,
    isLogoutRequest,
    isLogoutSuccess,
    isLogoutFailure,
    email,
    login: useCallback(
      (data: LoginRequest) => {
        // Clear cache on login!
        queryClient.clear();
        return dispatch(actions.login(data));
      },
      [dispatch],
    ),
    read: useCallback(
      (userId: string) => dispatch(actions.read(userId)).catch(console.error),
      [dispatch],
    ),
    getAuthContext: useCallback(
      () => dispatch(actions.authContext()),
      [dispatch],
    ),
  };
};

export default useLogin;
