import { ChainInfo } from '@keplr-wallet/types';
import allChains from 'components/Chain/chains';

export type ChainCosmos = ChainInfo;

const commonChainData = {
  bip44: {
    coinType: 118,
  },
  bech32Config: {
    bech32PrefixAccAddr: 'noble',
    bech32PrefixAccPub: 'noble' + 'pub',
    bech32PrefixValAddr: 'noble' + 'valoper',
    bech32PrefixValPub: 'noble' + 'valoperpub',
    bech32PrefixConsAddr: 'noble' + 'valcons',
    bech32PrefixConsPub: 'noble' + 'valconspub',
  },
  currencies: [
    {
      coinDenom: 'EURe',
      coinMinimalDenom: 'ueure',
      coinDecimals: 6,
      coinGeckoId: 'eure',
    },
    {
      coinDenom: 'STAKE',
      coinMinimalDenom: 'ustake',
      coinDecimals: 6,
      coinGeckoId: 'ustake',
    },
  ],
  stakeCurrency: {
    coinDenom: 'STAKE',
    coinMinimalDenom: 'ustake',
    coinDecimals: 6,
    coinGeckoId: 'stake',
  },
  features: ['ibc-transfer'],
};

const nobleChain = allChains.find((c) => c.id === 'noble');
const grandChain = allChains.find((c) => c.id === 'grand');
const florinChain = allChains.find((c) => c.id === 'florin');
const florinLocalChain = allChains.find((c) => c.id === 'florinlocal');

const chains: ChainInfo[] = [
  {
    chainId: nobleChain?.chainId as string,
    chainName: nobleChain?.chainId as string,
    rpc: 'https://noble-rpc.polkachu.com/',
    rest: 'https://noble-api.polkachu.com/',
    feeCurrencies: [
      {
        coinDenom: 'EURe',
        coinMinimalDenom: 'ueure',
        coinDecimals: 6,
        coinGeckoId: 'eure',
        gasPriceStep: {
          low: 0.09,
          average: 0.09,
          high: 0.09,
        },
      },
      {
        coinDenom: 'USDC',
        coinMinimalDenom: 'uusdc',
        coinDecimals: 6,
        coinGeckoId: 'usdc',
        gasPriceStep: {
          low: 20000,
          average: 20000,
          high: 20000,
        },
      },
    ],
    ...commonChainData,
  },
  {
    chainId: grandChain?.chainId as string,
    chainName: grandChain?.chainId as string,
    rpc: 'https://testnet-rpc.noble.xyz/',
    rest: 'https://testnet-api.noble.xyz/',
    feeCurrencies: [
      {
        coinDenom: 'STAKE',
        coinMinimalDenom: 'astake',
        coinDecimals: 18,
        coinGeckoId: 'stake',
        gasPriceStep: {
          low: 1,
          average: 1,
          high: 1,
        },
      },
    ],
    ...commonChainData,
  },
  {
    chainId: florinChain?.chainId as string,
    chainName: florinChain?.chainId as string,
    rpc: 'https://rpc.florin.noble.xyz',
    rest: 'https://api.florin.noble.xyz',
    feeCurrencies: [
      {
        coinDenom: 'USDC',
        coinMinimalDenom: 'uusdc',
        coinDecimals: 6,
        coinGeckoId: 'usdc',
        gasPriceStep: {
          low: 20000,
          average: 20000,
          high: 20000,
        },
      },
    ],
    ...commonChainData,
  },
  {
    chainId: florinLocalChain?.chainId as string,
    chainName: florinLocalChain?.chainId as string,
    rpc: 'http://localhost:26657',
    rest: 'http://localhost:1317',
    feeCurrencies: [
      {
        coinDenom: 'STAKE',
        coinMinimalDenom: 'astake',
        coinDecimals: 18,
        coinGeckoId: 'stake',
        gasPriceStep: {
          low: 1,
          average: 1,
          high: 1,
        },
      },
    ],
    ...commonChainData,
  },
];

export default chains;
