import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import emoney from 'customer/components/emoney/reducer';
import help from 'customer/components/help/reducer';
import loginConstants from 'customer/components/iam/Login/constants';
import iam from 'customer/components/iam/reducer';
import kyc from 'customer/components/kyc/reducer';
import layout from 'customer/components/Layout/reducer';
import notification from 'customer/components/notification/reducer';
import partner from 'customer/components/partner/reducer';
import policies from 'customer/components/policies/reducer';
import treasury from 'customer/components/treasury/reducer';

export const appReducer = combineReducers({
  app: (state) => state || null,
  iam,
  emoney,
  treasury,
  kyc,
  partner,
  help,
  policies,
  notification,
  layout,
});

type RootState = ReturnType<typeof appReducer>;

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  if (action?.type === loginConstants.LOGOUT_SUCCESS) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
