import { AnyAction } from 'redux';
import { CONFIRM_SUCCESS } from '../Confirm/constants';
import loginConstants from '../Login/constants';
import { SIGNUP_SUCCESS } from '../Signup/constants';
import constants from './constants';
import { IamProfileReduxStore, Profile } from './types';

const initial: IamProfileReduxStore = {
  isListRequest: false,
  isListSuccess: false,
  isListFailure: undefined,
  list: [],
  selected: undefined,
};

export default (state = initial, action: AnyAction) => {
  switch (action.type) {
    case constants.LIST_REQUEST: {
      return { ...state, isListRequest: true, isListFailure: undefined };
    }
    case constants.LIST_SUCCESS: {
      const { item: profiles } = action;
      return {
        ...state,
        list: profiles,
        isListRequest: false,
        isListSuccess: true,
        isListFailure: undefined,
      };
    }
    case constants.LIST_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isListRequest: false,
        isListSuccess: false,
        isListFailure: error,
      };
    }

    case CONFIRM_SUCCESS:
    case SIGNUP_SUCCESS:
    case loginConstants.LOGIN_SUCCESS: {
      const { item: authContext } = action;

      let selected: Profile | undefined;

      if (Array.isArray(authContext.profiles)) {
        if (authContext?.defaultProfile) {
          selected = authContext.profiles.find(
            (p: Profile) => p.id === authContext.defaultProfile,
          );
        }
        if (!selected && authContext.profiles.length > 0) {
          selected = authContext.profiles[0];
        }
      }

      return {
        ...state,
        list: authContext?.profiles || [],
        selected,
        isListRequest: false,
        isListSuccess: true,
        isListFailure: undefined,
      };
    }

    default:
      return state;
  }
};
