import { QueryClient } from '@tanstack/query-core';
import { QueryClientProvider } from '@tanstack/react-query';
import { useNotification } from 'components/Notification/hooks';
import { useSharedAppContext } from 'providers/AppConfig';
import React, { useEffect, useState } from 'react';
import { ValidationErrors } from 'types/validationTypes';

export const keyPrefix = (key: unknown[]) => ['monerium', ...key];

const ReactQueryProvider = ({ children }: { children: React.ReactNode }) => {
  const [queryClient] = useState(() => new QueryClient());
  const [Devtools, setDevtools] = useState<React.ReactNode>(null);

  const { notifyError } = useNotification();
  const { environment, isProxy } = useSharedAppContext();

  const isDev = environment === 'dev' || environment === 'staging' || isProxy;

  useEffect(() => {
    if (isDev) {
      /**
       * See how we can hide this button by default, but make is callable through window.
       * https://tanstack.com/query/latest/docs/framework/react/devtools#devtools-in-production
       */
      import('@tanstack/react-query-devtools').then(
        ({ ReactQueryDevtools }) => {
          setDevtools(<ReactQueryDevtools initialIsOpen={false} />);
        },
      );
    }
  }, []);

  const queryCache = queryClient.getQueryCache();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const callback = (event: any) => {
    if (event.action?.type === 'failed') {
      console.error(event.action?.error);

      // LOGGING:
      // eventType: event.type,
      // queryKey: event.query.queryKey,
      // queryHash: event.query.queryHash,
      // timestamp: new Date(event.query.state.dataUpdatedAt),
      // queryData: queryClient.getQueryData(event.query.queryKey),
    }
  };

  React.useEffect(() => {
    const unsubscribe = queryCache.subscribe(callback);
    return () => unsubscribe();
  }, []);

  // TODO: Remove, just for debugging purposes
  // queryClient.setMutationDefaults([], {
  //   onError: (error) => {
  //     console.error('Mutation catch-all error:', error);
  //   },
  // });

  // A catch-all for mutations
  queryClient.setMutationDefaults(['monerium'], {
    onError: (error: ValidationErrors) => {
      let message = error?.message;

      if (message === 'Validation errors') {
        message = `Validation errors: ${JSON.stringify(error?.errors)}`;
      }

      console.error('Mutation catch-all error:', error);
      if (message) {
        notifyError(message as string);
      }
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {Devtools}
    </QueryClientProvider>
  );
};
export default ReactQueryProvider;
