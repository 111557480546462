import { combineReducers } from '@reduxjs/toolkit';

import token from './Token/slice';
import tx from './Tx/reducer';
import { EmoneyReduxStore } from './types';

export default combineReducers<EmoneyReduxStore>({
  token,
  tx,
});
