import { AnyAction } from 'redux';
import constants from './constants';
import { PoliciesCookieNoticeReduxStore } from './types';

const initial: PoliciesCookieNoticeReduxStore = {
  isTestRequest: false,
  isTestSuccess: false,
  isTestFailure: false,

  isGetRequest: false,
  isGetSuccess: false,
  isGetFailure: false,

  isAcceptRequest: false,
  isAcceptSuccess: false,
  isAcceptFailure: false,

  cookiesEnabled: true,
  cookiesAccepted: true,
};

export default (state = initial, action: AnyAction) => {
  switch (action.type) {
    case constants.TEST_REQUEST: {
      return { ...state, isTestRequest: true };
    }
    case constants.TEST_SUCCESS: {
      const { cookiesEnabled } = action;
      return {
        ...state,
        cookiesEnabled,
        isTestRequest: false,
        isTestSuccess: true,
        isTestFailure: false,
      };
    }
    case constants.TEST_FAILURE: {
      return {
        ...state,
        cookiesEnabled: false,
        isTestRequest: false,
        isTestSuccess: false,
        isTestFailure: true,
      };
    }

    case constants.GET_REQUEST: {
      return { ...state, isGetRequest: true };
    }
    case constants.GET_SUCCESS: {
      const { cookiesAccepted } = action;
      return {
        ...state,
        cookiesAccepted,
        isGetRequest: false,
        isGetSuccess: true,
        isGetFailure: false,
      };
    }
    case constants.GET_FAILURE: {
      return {
        ...state,
        cookiesAccepted: false,
        isGetRequest: false,
        isGetSuccess: false,
        isGetFailure: true,
      };
    }

    case constants.ACCEPT_REQUEST: {
      return { ...state, isAcceptRequest: true };
    }
    case constants.ACCEPT_SUCCESS: {
      return {
        ...state,
        cookiesAccepted: true,
        isAcceptRequest: false,
        isAcceptSuccess: true,
        isAcceptFailure: false,
      };
    }
    case constants.ACCEPT_FAILURE: {
      return {
        ...state,
        cookiesAccepted: false,
        isAcceptRequest: false,
        isAcceptSuccess: false,
        isAcceptFailure: true,
      };
    }

    default:
      return state;
  }
};
