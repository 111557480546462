import { AnyAction } from 'redux';
import { STORE_SUCCESS as USER_STORE_SUCCESS } from '../User/constants';
import {
  CREATE_VERSION_FAILURE,
  CREATE_VERSION_REQUEST,
  CREATE_VERSION_SUCCESS,
  READ_FAILURE,
  READ_REQUEST,
  READ_SUCCESS,
  RESET_FAILURE,
  RESET_REQUEST,
  RESET_SUCCESS,
  STORE_FAILURE,
  STORE_REQUEST,
  STORE_SUCCESS,
  VERSIONS_FAILURE,
  VERSIONS_REQUEST,
  VERSIONS_SUCCESS,
} from './constants';
import { ProfileReduxStore } from './types';

export const initial: ProfileReduxStore = {
  detail: undefined,
  versions: undefined,
  isReadRequest: false,
  isReadSuccess: false,
  isReadFailure: undefined,
  isStoreRequest: false,
  isStoreSuccess: false,
  isStoreFailure: undefined,
  isCreateVersionRequest: false,
  isCreateVersionSuccess: false,
  isCreateVersionFailure: undefined,
  isVersionsRequest: false,
  isVersionsSuccess: false,
  isVersionsFailure: undefined,
  isResetRequest: false,
  isResetSuccess: false,
  isResetFailure: undefined,
  validationErrors: undefined,
};

export default (
  state: ProfileReduxStore = initial,
  action: AnyAction,
): ProfileReduxStore => {
  switch (action.type) {
    case READ_REQUEST: {
      return { ...state, isReadRequest: true };
    }
    case READ_SUCCESS: {
      const { item } = action;
      return {
        ...state,
        detail: item,
        isReadRequest: false,
        isReadSuccess: true,
        isReadFailure: undefined,
      };
    }
    case READ_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isReadRequest: false,
        isReadSuccess: false,
        isReadFailure: error,
      };
    }

    case STORE_REQUEST: {
      const { item, error } = action;

      return {
        ...state,
        detail: {
          ...state.detail,
          ...(item || {}),
        },
        isStoreRequest: true,
        validationErrors: error,
      };
    }

    // we need to detect when user changes are made to allow submitting a new
    // profile version.
    case USER_STORE_SUCCESS: {
      return {
        ...state,
        detail: {
          ...(state.detail || {}),
          meta: {
            ...(state.detail?.meta || {}),
            state: 'draft',
          },
        },
      };
    }

    case STORE_SUCCESS: {
      const { item } = action;

      return {
        ...state,
        detail: { ...(state.detail || {}), ...(item || {}) },
        isStoreRequest: false,
        isStoreSuccess: true,
        isStoreFailure: undefined,
      };
    }
    case STORE_FAILURE: {
      const { error } = action;

      return {
        ...state,
        isStoreRequest: false,
        isStoreSuccess: false,
        isStoreFailure: error,
        validationErrors: error,
      };
    }

    case RESET_REQUEST: {
      return { ...state, isResetRequest: true };
    }

    case RESET_SUCCESS: {
      const { item } = action;
      return {
        ...state,
        detail: item,
        isResetRequest: false,
        isResetSuccess: true,
        isResetFailure: undefined,
      };
    }
    case RESET_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isResetRequest: false,
        isResetSuccess: false,
        isResetFailure: error,
        validationErrors: error,
      };
    }

    case CREATE_VERSION_REQUEST: {
      return {
        ...state,
        isCreateVersionRequest: true,
        isCreateVersionFailure: undefined,
        validationErrors: undefined,
      };
    }
    case CREATE_VERSION_SUCCESS: {
      const { item } = action;

      return {
        ...state,
        detail: item,
        isCreateVersionRequest: false,
        isCreateVersionSuccess: false,
        isCreateVersionFailure: undefined,
        validationErrors: undefined,
      };
    }
    case CREATE_VERSION_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isCreateVersionRequest: false,
        isCreateVersionSuccess: false,
        isCreateVersionFailure: error,
        validationErrors: error,
      };
    }

    case VERSIONS_REQUEST: {
      return { ...state, isVersionsRequest: true };
    }
    case VERSIONS_SUCCESS: {
      const { versions } = action;
      return {
        ...state,
        versions,
        isVersionsRequest: false,
        isVersionsSuccess: true,
        isVersionsFailure: undefined,
      };
    }
    case VERSIONS_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isVersionsRequest: false,
        isVersionsSuccess: false,
        isVersionsFailure: error,
      };
    }

    default:
      return state;
  }
};
