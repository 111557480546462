import UniversalRouter from 'universal-router';
import routes from './routes';

export default new UniversalRouter(routes, {
  async resolveRoute(context, params) {
    if (typeof context.route.action === 'function') {
      const r = await context.route.action(context, params);
      if (typeof r?.default === 'function') {
        // loaded via import for code splitting
        return r?.default(context, params);
      }
      if (r) {
        return r;
      }
    }
    return undefined;
  },
});
