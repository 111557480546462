import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ReduxStore } from 'src/customer/store/configureStore';
import { AuthContext } from '../Login/types';
import service from './service';
import { SetPasswordRequest, User } from './types';

/* https://redux-toolkit.js.org/usage/usage-with-typescript#type-safety-with-extrareducers */

const invite = createAsyncThunk(
  'IAM_INVITE',
  async (
    { profileId, email }: { profileId: string; email: string },
    { getState, requestId },
  ) => {
    const { inviteSlice } = (getState() as ReduxStore).iam.invite;
    if (
      inviteSlice.status === 'request' &&
      requestId === inviteSlice.currentRequestId
    ) {
      return service.invite(profileId, email);
    }
    // do nothing
    return Promise.resolve({} as User);
  },
);

const setPassword = createAsyncThunk(
  'IAM_INVITE_SET_PASS',
  async (PasswordRequest: SetPasswordRequest, { getState, requestId }) => {
    const { setPassSlice } = (getState() as ReduxStore).iam.invite;
    if (
      setPassSlice.status === 'request' &&
      requestId === setPassSlice.currentRequestId
    ) {
      return service.setPassword(PasswordRequest);
    }
    // do nothing
    return Promise.resolve({} as AuthContext);
  },
);

export default {
  invite,
  setPassword,
};
