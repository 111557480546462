import { ViemChains, ViemTransports } from 'components/Wallet/evm/types';
import { http } from 'viem';
import { Config, createConfig } from 'wagmi';
import {
  coinbaseWallet,
  injected,
  safe,
  walletConnect,
} from 'wagmi/connectors';

const TEST_WALLETCONNECT_PROJECT_ID = 'e39e5a24285fe0a946cd3b6d9eada7af';
const PROD_WALLETCONNECT_PROJECT_ID = '9dfea1e28fa874dc0d8c95fb02d58886';

let cfg: Config | undefined;

export const config = (
  env: 'dev' | 'staging' | 'sandbox' | 'production',
  chains: ViemChains,
) => {
  if (cfg) {
    return cfg;
  }
  const projectId =
    env === 'production'
      ? PROD_WALLETCONNECT_PROJECT_ID
      : TEST_WALLETCONNECT_PROJECT_ID;

  const transports = chains.reduce((acc: ViemTransports, chain) => {
    const key = chain.id as keyof ViemTransports;
    acc[key] = http() as ViemTransports[keyof ViemTransports];
    return acc;
  }, {} as ViemTransports);

  cfg = createConfig({
    ssr: true,
    multiInjectedProviderDiscovery: false,
    chains: chains,
    transports: transports,
    connectors: [
      injected(),
      walletConnect({
        projectId,
        qrModalOptions: { themeVariables: { '--wcm-z-index': '9999999' } },
      }),
      coinbaseWallet(),
      safe({
        // shimDisconnect: true,
        allowedDomains: [
          /app\.safe\.global$/,
          /^.*\.coinshift\.global$/,
          /localhost:5173$/,
        ],
      }),
    ],
  });
  return cfg;
};
