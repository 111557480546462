import Button from 'components/Button';
import { SnackbarKey, useSnackbar } from 'notistack';
import { ReactNode } from 'react';
import { Alert } from './types';

export const useNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const notify = ({ variant, message, persist }: Alert) => {
    return enqueueSnackbar(message, {
      autoHideDuration: 15000,
      // preventDuplicate: true,
      persist: persist,
      variant,
      anchorOrigin: {
        horizontal: 'left',
        vertical: 'bottom',
      },

      style: {
        zIndex: 999999,
      },
      action: (k: SnackbarKey): ReactNode => (
        <Button small icon="close" white onClick={() => closeSnackbar(k)} />
      ),
    });
  };

  return {
    /**
     * Red alert
     * @param {string} message message to display
     * @param {boolean} persist stays in view - default is true
     */
    notifyError: (message: string, persist: boolean = true) =>
      notify({ variant: 'error', message, persist }),
    /**
     * Green alert
     * @param message message to display
     * @param persist stays in view - default is false
     */
    notifySuccess: (message: string, persist: boolean = false) =>
      notify({ variant: 'success', message, persist }),
    /**
     * Blue alert
     * @param message message to display
     * @param persist stays in view - default is false
     */
    notifyInfo: (message: string, persist: boolean = false) =>
      notify({ variant: 'info', message, persist }),
    /**
     * Orange alert
     * @param message message to display
     * @param persist stays in view - default is true
     */
    notifyWarning: (message: string, persist: boolean = true) =>
      notify({ variant: 'warning', message, persist }),
  };
};
