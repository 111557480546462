/* eslint-disable no-param-reassign */ // recommended by redux-toolkit to work with immer.
import { createSlice } from '@reduxjs/toolkit';
import { ValidationErrors } from 'types/validationTypes';
import actions from './actions';
import { IamInviteReduxStore } from './types';

const initialState: IamInviteReduxStore = {
  details: undefined,
  inviteSlice: {
    status: 'idle',
    currentRequestId: '',
  },
  setPassSlice: {
    status: 'idle',
    currentRequestId: '',
  },
};

/*
 * https://redux-toolkit.js.org/usage/usage-with-typescript
 */

const inviteSlice = createSlice({
  name: 'invite',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(actions.invite.pending, (state, action) => {
        state.inviteSlice.status = 'request';
        state.inviteSlice.currentRequestId = action.meta.requestId;
        state.inviteSlice.error = undefined;
      })
      .addCase(actions.invite.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.inviteSlice.status === 'request' &&
          state.inviteSlice.currentRequestId === requestId
        ) {
          state.inviteSlice.status = 'failed';
          state.inviteSlice.error = action.error as ValidationErrors;
          state.inviteSlice.currentRequestId = '';
        }
      })
      .addCase(actions.invite.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.inviteSlice.status === 'request' &&
          state.inviteSlice.currentRequestId === requestId
        ) {
          state.inviteSlice.error = undefined;
          state.inviteSlice.status = 'success';
          state.details = action.payload;
          state.inviteSlice.currentRequestId = '';
        }
      })
      .addCase(actions.setPassword.pending, (state, action) => {
        state.setPassSlice.status = 'request';
        state.setPassSlice.currentRequestId = action.meta.requestId;
        state.setPassSlice.error = undefined;
      })
      .addCase(actions.setPassword.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.setPassSlice.status === 'request' &&
          state.setPassSlice.currentRequestId === requestId
        ) {
          state.setPassSlice.status = 'failed';
          state.setPassSlice.error = action.error as ValidationErrors;
          state.setPassSlice.currentRequestId = '';
        }
      })
      .addCase(actions.setPassword.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.setPassSlice.status === 'request' &&
          state.setPassSlice.currentRequestId === requestId
        ) {
          state.setPassSlice.error = undefined;
          state.setPassSlice.status = 'success';
          state.setPassSlice.currentRequestId = '';
        }
      });
  },
});

export default inviteSlice.reducer;
