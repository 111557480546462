import QueryString from 'query-string';

export const stringifyQuery = (query: Record<string, string>) =>
  QueryString.stringify(query);

export const addQueryParamsToUrl = (
  url: string,
  query: Record<string, string>,
) => {
  const q = QueryString.parseUrl(url, { parseFragmentIdentifier: true });
  return QueryString.stringifyUrl({
    ...q,
    query: {
      ...(q?.query || {}),
      ...query,
    },
  });
};

/**
 * Checks if the link provided is the root of the path given.
 * @param pathname the active path in the browser
 * @param link the string you want to check if it is the root of the pathname
 */
export const isBasePath = (pathname: string, link: string): boolean => {
  if (!link || typeof link !== 'string') return false;
  if (!pathname || typeof pathname !== 'string') return false;
  if (pathname.length < link.length) return false;
  return pathname.substring(0, link.length) === link;
};

export default {
  isBasePath,
  stringifyQuery,
  addQueryParamsToUrl,
};
