export const LIST_REQUEST = 'KYC_PROFILE_LIST_REQUEST';
export const LIST_SUCCESS = 'KYC_PROFILE_LIST_SUCCESS';
export const LIST_FAILURE = 'KYC_PROFILE_LIST_FAILURE';
export const READ_REQUEST = 'KYC_PROFILE_READ_REQUEST';
export const READ_SUCCESS = 'KYC_PROFILE_READ_SUCCESS';
export const READ_FAILURE = 'KYC_PROFILE_READ_FAILURE';
export const STORE_REQUEST = 'KYC_PROFILE_STORE_REQUEST';
export const STORE_SUCCESS = 'KYC_PROFILE_STORE_SUCCESS';
export const STORE_FAILURE = 'KYC_PROFILE_STORE_FAILURE';
export const CREATE_VERSION_REQUEST = 'KYC_PROFILE_CREATE_VERSION_REQUEST';
export const CREATE_VERSION_SUCCESS = 'KYC_PROFILE_CREATE_VERSION_SUCCESS';
export const CREATE_VERSION_FAILURE = 'KYC_PROFILE_CREATE_VERSION_FAILURE';
export const VERSIONS_FAILURE = 'KYC_VERSIONS_FAILURE';
export const VERSIONS_REQUEST = 'KYC_VERSIONS_REQUEST';
export const VERSIONS_SUCCESS = 'KYC_VERSIONS_SUCCESS';
export const RESET_FAILURE = 'KYC_RESET_FAILURE';
export const RESET_REQUEST = 'KYC_RESET_REQUEST';
export const RESET_SUCCESS = 'KYC_RESET_SUCCESS';
export const PROFILE_KIND_PERSONAL = 'personal';
export const PROFILE_KIND_CORPORATE = 'corporate';

export default {
  LIST_REQUEST,
  LIST_SUCCESS,
  LIST_FAILURE,
  READ_REQUEST,
  READ_SUCCESS,
  READ_FAILURE,
  STORE_REQUEST,
  STORE_SUCCESS,
  STORE_FAILURE,
  CREATE_VERSION_REQUEST,
  CREATE_VERSION_SUCCESS,
  CREATE_VERSION_FAILURE,
  VERSIONS_FAILURE,
  VERSIONS_REQUEST,
  VERSIONS_SUCCESS,
  RESET_REQUEST,
  RESET_SUCCESS,
  RESET_FAILURE,
  PROFILE_KIND_PERSONAL,
  PROFILE_KIND_CORPORATE,
};
