import rest from 'services/rest';
import { AuthContext, LoginRequest, LogoutResponse } from './types';

const authContext = (): Promise<AuthContext> =>
  rest.get('/api/iam/auth/context');

const login = (data: LoginRequest): Promise<AuthContext> =>
  rest.post('/api/iam/login', data);

const logout = (): Promise<LogoutResponse> => rest.get('/api/iam/logout');

const read = (id: string): Promise<AuthContext> =>
  rest.get(`/api/iam/users/${id}`);

export default {
  authContext,
  login,
  logout,
  read,
};
