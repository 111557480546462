/* eslint-disable no-param-reassign */ // recommended by redux-toolkit to work with immer.
import { createSlice } from '@reduxjs/toolkit';

const initialState: { right: { isOpen: boolean } } = {
  right: {
    isOpen: false,
  },
};

/*
 * https://redux-toolkit.js.org/usage/usage-with-typescript
 */

export const { actions, reducer } = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    closeRightDrawer(state) {
      state.right.isOpen = false;
    },
    openRightDrawer(state) {
      state.right.isOpen = true;
    },
  },
});

export default reducer;
