import { ALERT_ADD, ALERT_CLOSE } from './constants';
import { AlertAction, AlertStore } from './types';

const initialState: AlertStore = {
  alerts: [],
};

export default (
  state: AlertStore = initialState,
  action: AlertAction,
): AlertStore => {
  switch (action.type) {
    case ALERT_ADD:
      return {
        ...state,
        alerts: [...state.alerts, action.alert],
      };

    case ALERT_CLOSE:
      return {
        ...state,
        alerts: state.alerts.map((alert) =>
          alert.key === action.key
            ? { ...alert, dismissed: true }
            : { ...alert },
        ),
      };

    default:
      return state;
  }
};
