import { AnyAction } from 'redux';
import {
  CONFIRM_FAILURE,
  CONFIRM_REQUEST,
  CONFIRM_SUCCESS,
  RESEND_FAILURE,
  RESEND_REQUEST,
  RESEND_SUCCESS,
} from './constants';
import { IamConfirmReduxStore } from './types';

const initial: IamConfirmReduxStore = {
  auth: undefined,
  isConfirmRequest: false,
  isConfirmSuccess: false,
  isConfirmFailure: undefined,
  isResendRequest: false,
  isResendSuccess: false,
  isResendFailure: undefined,
};

export default (state = initial, action: AnyAction) => {
  switch (action.type) {
    case CONFIRM_REQUEST: {
      return { ...state, isConfirmRequest: true, isConfirmFailure: undefined };
    }
    case CONFIRM_SUCCESS: {
      const { item: authContext } = action;

      return {
        ...state,
        auth: authContext,
        isConfirmRequest: false,
        isConfirmSuccess: true,
        isConfirmFailure: undefined,
      };
    }
    case CONFIRM_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isConfirmRequest: false,
        isConfirmSuccess: false,
        isConfirmFailure: error,
      };
    }
    case RESEND_REQUEST: {
      return { ...state, isResendRequest: true, isResendFailure: undefined };
    }

    case RESEND_SUCCESS: {
      return {
        ...state,
        isResendRequest: false,
        isResendSuccess: true,
        isResendFailure: undefined,
      };
    }
    case RESEND_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isResendRequest: false,
        isResendSuccess: false,
        isResendFailure: error,
      };
    }
    default:
      return state;
  }
};
