import { getSupportedChains } from 'components/Wallet/util';
import { useSharedAppContext } from 'providers/AppConfig';
import React, { createContext, useMemo } from 'react';
import { Chain } from 'types/emoney/Chain/indexV2';

export const ChainsContext = createContext<Chain[]>([]);

/**
 * Enrich the list of supported chains with additional data.
 * @param supportedChains - The list of chains to support, passed on from CHAINS, the environment variable.
 * @param children - The children components.
 *
 * @returns List of supported chains and their identifiers.
 */
export const ChainsProvider = ({ children }: { children: React.ReactNode }) => {
  const { chains: supportedChains } = useSharedAppContext();

  const chains = getSupportedChains(supportedChains);

  const contextValues = useMemo(() => chains, [supportedChains]);

  return (
    <ChainsContext.Provider value={contextValues}>
      {children}
    </ChainsContext.Provider>
  );
};
