import { Chain } from 'types/emoney/Chain/indexV2';

/**
 * Constructs the full URL to view an address on the block explorer for the specified chain.
 *
 * @param {Chain} chain - The supported chain object.
 * @param {string} address - The blockchain address to view on the block explorer.
 * @returns {string | undefined} - The full URL to view the address on the block explorer, or `undefined` if the URL is not available.
 */
export const getAddressUrl = (chain: Chain, address: string) => {
  if (chain.kind === 'cosmos') {
    return `${chain.explorerUrl}/account/${address}`;
  }
  return `${chain.explorerUrl}/address/${address}`;
};

/**
 * Constructs the full URL for a transaction on the block explorer.
 *
 * @param {string} hash - The transaction hash.
 * @returns {string | undefined} - The full transaction URL on the block explorer, or `undefined` if the URL is not available.
 */
export const getTransactionUrl = (chain: Chain, hash: string) => {
  return `${chain.explorerUrl}/tx/${hash}`;
};

/**
 * Constructs the full URL for a token on the block explorer.
 *
 * @param {string} contractAddress - The token identifier (e.g., contract address).
 * @returns {string | undefined} - The full token URL on the block explorer, or `undefined` if the URL is not available.
 */
export const getTokenUrl = (chain: Chain, contractAddress: string) => {
  // Does not apply to Cosmos.
  if (chain.kind === 'cosmos') {
    return undefined;
  }
  return `${chain.explorerUrl}/token/${contractAddress}`;
};

/**
 * Constructs the full URL for a block on the block explorer.
 *
 * @param {string} hash - The block identifier.
 * @returns {string | undefined} - The full block URL on the block explorer, or `undefined` if the URL is not available.
 */
export const getBlockUrl = (chain: Chain, hash: string) => {
  return `${chain.explorerUrl}/block/${hash}`;
};
/**
 * Constructs the full URL for a token, filtered by address on the block explorer.
 *
 * @param {Chain} chain - The supported chain object.
 * @param {string} contractAddress - The token identifier (e.g., contract address).
 * @param {string} address - The blockchain address to filter by.
 * @returns {string | undefined} - The full token URL on the block explorer, or `undefined` if the URL is not available.
 */
export const getExplorerTokenByAddressUrl = (
  chain: Chain,
  contractAddress: string,
  address: string,
) => {
  // Does not apply to Cosmos.
  if (chain.kind === 'cosmos') {
    return undefined;
  }
  if (chain.chainId === 10200) {
    return `${chain.explorerUrl}/address/${address}?tab=token_transfers&token=${contractAddress}`;
  }
  return `${chain.explorerUrl}/token/${contractAddress}?a=${address}`;
};
