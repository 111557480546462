export const READ_REQUEST = 'KYC_USER_READ_REQUEST' as const;
export const READ_SUCCESS = 'KYC_USER_READ_SUCCESS' as const;
export const READ_FAILURE = 'KYC_USER_READ_FAILURE' as const;
export const STORE_REQUEST = 'KYC_USER_STORE_REQUEST' as const;
export const STORE_SUCCESS = 'KYC_USER_STORE_SUCCESS' as const;
export const STORE_FAILURE = 'KYC_USER_STORE_FAILURE' as const;
export const CREATE_VERSION_REQUEST =
  'KYC_USER_CREATE_VERSION_REQUEST' as const;
export const CREATE_VERSION_SUCCESS =
  'KYC_USER_CREATE_VERSION_SUCCESS' as const;
export const CREATE_VERSION_FAILURE =
  'KYC_USER_CREATE_VERSION_FAILURE' as const;
