import { ValidationErrors } from 'src/types/validationTypes';

export const stringifyErrors = (error: ValidationErrors): string => {
  let result = '';

  function traverseErrors(e: ValidationErrors, prefix = '') {
    Object.entries(e).forEach(([key, value]) => {
      if (typeof value === 'object') {
        traverseErrors(value, `${prefix}${key}.`);
      } else {
        result += `'${prefix}${key}': ${value}. `;
      }
    });
  }
  if (error) traverseErrors(error);

  return result.trim();
};
