export default {
  LIST_REQUEST: 'THREAD_LIST_REQUEST',
  LIST_SUCCESS: 'THREAD_LIST_SUCCESS',
  LIST_FAILURE: 'THREAD_LIST_FAILURE',

  READ_REQUEST: 'THREAD_READ_REQUEST',
  READ_SUCCESS: 'THREAD_READ_SUCCESS',
  READ_FAILURE: 'THREAD_READ_FAILURE',

  CREATE_REQUEST: 'THREAD_CREATE_REQUEST',
  CREATE_SUCCESS: 'THREAD_CREATE_SUCCESS',
  CREATE_FAILURE: 'THREAD_CREATE_FAILURE',

  REPLY_REQUEST: 'THREAD_REPLY_REQUEST',
  REPLY_SUCCESS: 'THREAD_REPLY_SUCCESS',
  REPLY_FAILURE: 'THREAD_REPLY_FAILURE',

  UPDATE_DRAFT: 'THREAD_UPDATE_DRAFT',
};
