import { App } from 'types/iam/App';

/** Splits the redirectUris CSV string into an array */
export const splitAppUris = (app: App) => {
  const newClients = app.clients?.map((client) => {
    if (client.kind === 'auth_flow' && client.redirectUris !== undefined) {
      return { ...client, redirectUrisArray: client.redirectUris.split(/,/) };
    }
    return client;
  });
  return { ...app, clients: newClients };
};

export const splitAppsUris = (apps: App[]) => {
  const appList = apps.map((app) => {
    return splitAppUris(app);
  });
  return appList;
};

export default {
  splitAppUris,
  splitAppsUris,
};
