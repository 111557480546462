import { getDateTime } from 'utils/datetime';

export const getCacheId = ({ address, tokenAddress }) =>
  `${address}${tokenAddress}`.toLowerCase();

export const mapOrderTx = (list) =>
  typeof list?.map === 'function' &&
  list.map((tx) => ({
    ...tx,
    // todo: refactor this in EMS API
    kind: tx.kind === 'mint' ? 'issue' : 'redeem',
  }));

export const isOrder = (tx) => {
  const { kind } = tx;
  return kind === 'issue' || kind === 'redeem';
};

export const isApproved = (tx) => tx?.meta?.state === 'processed';
export const isRejected = (tx) => tx?.meta?.state === 'rejected';
export const isPending = (tx) => {
  if (isOrder(tx)) {
    return !isApproved(tx) && !isRejected(tx);
  }
  return !tx?.blockNumber && tx?.meta?.state !== 'dropped';
};

export const displayStatus = (tx) => {
  if (isApproved(tx)) return 'Completed';
  if (isRejected(tx)) return 'Rejected';
  return 'In progress';
};

export const displayStatusColor = (tx) => {
  if (isApproved(tx)) return 'success';
  if (isRejected(tx)) return 'danger';
  return 'default';
};

export const getDirection = (tx) => {
  const kind = tx?.kind;
  return kind === 'issue' || kind === 'receive' ? 'in' : 'out';
};

export const getTimestamp = (tx) =>
  tx?.meta?.placedAt && getDateTime(tx.meta.placedAt);

export const getName = (tx) => {
  const { counterpart } = tx;
  if (counterpart?.details?.name) return counterpart?.details?.name;
  if (counterpart?.details?.companyName) return counterpart.details.companyName;
  if (counterpart?.details?.firstName)
    return `${counterpart.details.firstName} ${counterpart?.details?.lastName}`.trim();
  if (counterpart?.details?.lastName) return counterpart.details.lastName;
  return '';
};

export default {
  getCacheId,
  isOrder,
  isPending,
  isApproved,
  isRejected,
  getDirection,
  getName,
};
