import { createSlice } from '@reduxjs/toolkit';
import { RecipientType } from '../../types';

const initialState = {
  amount: '',
  companyName: '',
  firstName: '',
  lastName: '',
  country: '',
  iban: '',
  memo: '',
  recipientType: 'Individual' as RecipientType,
  supportingDocument: undefined,
  errors: {} as Record<string, string>,
};
const slice = createSlice({
  name: 'send',
  initialState,
  reducers: {
    setAmount: (state, action) => {
      state.amount = action.payload;
      delete state.errors?.amount;
    },
    setCompanyName: (state, action) => {
      state.companyName = action.payload;
      delete state.errors?.companyName;
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload;
      delete state.errors?.firstName;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
      delete state.errors?.lastName;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
      delete state.errors?.country;
    },
    setIban: (state, action) => {
      state.iban = action.payload;
      delete state.errors?.iban;
    },
    setMemo: (state, action) => {
      state.memo = action.payload;
      delete state.errors?.memo;
    },
    setSupportingDocument: (state, action) => {
      state.supportingDocument = action.payload;
      delete state.errors?.supportingDocument;
    },
    setRecipientType: (state, action) => {
      state.recipientType = action.payload;
    },
    setError: (state, action) => {
      state.errors = { ...state.errors, ...action.payload };
    },
    reset: () => {
      return initialState;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;
