import { AnyAction } from 'redux';
import constants from './constants';
import { EmoneyTxReduxStore, Transaction } from './types';
import { getCacheId } from './util';

const initial: EmoneyTxReduxStore = {
  isOrderRequest: {},
  isOrderSuccess: {},
  isOrderFailure: {},
  orders: {},
  isListRequest: {},
  isListSuccess: {},
  isListFailure: {},
  list: {},
  error: undefined,
};

export default (state = initial, action: AnyAction) => {
  switch (action.type) {
    case constants.ORDER_REQUEST: {
      const { orderId, req } = action;
      return {
        ...state,
        isOrderRequest: { ...state.isOrderRequest, [orderId]: req },
      };
    }
    case constants.ORDER_SUCCESS: {
      const { orders, orderId } = action;
      return {
        ...state,
        orders: { ...state.orders, [orderId]: orders },
        isOrderRequest: { ...state.isOrderRequest, [orderId]: null },
        isOrderSuccess: { ...state.isOrderSuccess, [orderId]: true },
        isOrderFailure: { ...state.isOrderFailure, [orderId]: false },
        error: {},
      };
    }
    case constants.ORDER_FAILURE: {
      const { error, orderId } = action;
      return {
        ...state,
        error,
        isOrderRequest: { ...state.isOrderRequest, [orderId]: null },
        isOrderSuccess: { ...state.isOrderSuccess, [orderId]: false },
        isOrderFailure: { ...state.isOrderFailure, [orderId]: true },
      };
    }

    case constants.LIST_REQUEST: {
      const { address, tokenAddress, req } = action;
      const cacheId = getCacheId({ address, tokenAddress });
      return {
        ...state,
        isListRequest: {
          ...state.isListRequest,
          [cacheId]: req,
        },
      };
    }
    case constants.LIST_SUCCESS: {
      const { tx, address, tokenAddress } = action;
      const cacheId = getCacheId({ address, tokenAddress });
      let txList = tx;
      if (Object.prototype.hasOwnProperty.call(state.list, cacheId)) {
        const prev = state.list[cacheId];
        const txNew = tx.reduce((all: Transaction[], i: Transaction) => {
          if (!prev.find((p) => p.txHash === i.txHash)) {
            all.push(i);
          }
          return all;
        }, []);
        txList = [...prev, ...txNew];
      }
      return {
        ...state,
        list: { ...state.list, [cacheId]: txList },
        isListRequest: {
          ...state.isListRequest,
          [cacheId]: null,
        },
        isListSuccess: {
          ...state.isListSuccess,
          [cacheId]: true,
        },
        isListFailure: {
          ...state.isListFailure,
          [cacheId]: false,
        },
        error: {},
      };
    }
    case constants.LIST_FAILURE: {
      const { error, address, tokenAddress } = action;
      const cacheId = getCacheId({ address, tokenAddress });
      return {
        ...state,
        list: { ...state.list, [cacheId]: [] },
        error,
        isListRequest: {
          ...state.isListRequest,
          [cacheId]: null,
        },
        isListSuccess: {
          ...state.isListSuccess,
          [cacheId]: false,
        },
        isListFailure: {
          ...state.isListFailure,
          [cacheId]: error,
        },
      };
    }

    case constants.CREATE_SUCCESS: {
      const { tx } = action;
      if (!tx?.txHash || !tx?.address || !tx?.tokenAddress) {
        return {
          ...state,
        };
      }
      const { address, tokenAddress } = tx;
      const cacheId = getCacheId({ address, tokenAddress });
      let txList = [tx];
      if (Object.prototype.hasOwnProperty.call(state.list, cacheId)) {
        txList = [...state.list[cacheId]];
        const i = txList.findIndex((t) => t.id === tx.id);
        if (i >= 0) {
          txList = [...txList.slice(0, i), tx, ...txList.slice(i + 1)];
        } else {
          txList.unshift(tx);
        }
      }
      return {
        ...state,
        list: { ...state.list, [cacheId]: txList },
      };
    }

    default:
      return state;
  }
};
