import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import {
  Action,
  configureStore,
  Middleware,
  PreloadedState,
  ThunkAction,
} from '@reduxjs/toolkit';
import rootReducer from 'customer/reducer';
import { AppThunkDispatch } from '../types';

function store(
  preloadedState?: PreloadedState<typeof rootReducer>,
  createLogger?: () => Middleware,
  middlewareConfig: Middleware[] = [],
) {
  let logger: Middleware;

  const configureAppStore = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => {
      if (__DEV__ && createLogger) {
        logger = createLogger();
        return getDefaultMiddleware({
          // redux-toolkit recommends having these as true, but needs some rewrite.
          immutableCheck: false,
          serializableCheck: false,
        })
          .prepend(middlewareConfig)
          .concat(logger);
      }
      return getDefaultMiddleware().prepend(middlewareConfig);
    },
    devTools: __DEV__,
    enhancers: (defaultEnhancers) => [...defaultEnhancers],
  });

  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if (__DEV__ && module.hot) {
    module.hot.accept('../reducer', () =>
      // eslint-disable-next-line  @typescript-eslint/no-var-requires,  global-require
      configureAppStore.replaceReducer(require('../reducer').default),
    );
  }

  return configureAppStore;
}

export type AppStore = ReturnType<typeof store>;
export type AppDispatch = AppStore['dispatch'];
export type AppState = AppStore['getState'];
export type ReduxStore = ReturnType<typeof rootReducer>;

export const useAppDispatch = () => useDispatch<AppThunkDispatch>();
export const useAppSelector: TypedUseSelectorHook<ReduxStore> = useSelector;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  ReduxStore,
  unknown,
  Action
>;

export default store;
