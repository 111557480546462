import { useCallback, useEffect } from 'react';
import {
  RootStateOrAny,
  shallowEqual,
  useDispatch,
  useSelector,
} from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { Token } from 'src/types/emoney/Token';
import { ThunkStatus } from 'src/types/store';
import { list } from './thunks';

const selectToken = (state: RootStateOrAny) => state.emoney?.token;

export const tokenSelector = createSelector(
  selectToken,
  (token) => token?.list || [],
);
export const tokenStatusSelector = createSelector(
  selectToken,
  (token) => token?.status || [],
);

interface UseTokenReturn {
  tokens: Token[];
  getTokens: () => void;
  status: ThunkStatus;
}

const useToken = (): UseTokenReturn => {
  const dispatch = useDispatch();

  const tokens = useSelector(tokenSelector, shallowEqual);
  const tokensStatus = useSelector(tokenStatusSelector, shallowEqual);

  const getTokens = useCallback(async () => dispatch(list()), [dispatch]);

  return {
    tokens,
    status: tokensStatus,
    getTokens,
  };
};

export default useToken;
