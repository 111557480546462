import { combineReducers } from 'redux';
import send from './Account/Send/reducer';

/**
 * When `account` has been refactored to use redux-toolkit, we won't need to define the type for combineReducers anymore
 */

export default combineReducers<{ send: ReturnType<typeof send> }>({
  send,
});
