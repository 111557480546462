import { AnyAction } from 'redux';
import { updateItemInArray } from 'src/utils/array';
import constants from './constants';
import { ThreadStore } from './types';

const initial: ThreadStore = {
  isListRequest: false,
  isListSuccess: false,
  isListFailure: undefined,
  list: [],
  isCreateRequest: false,
  isCreateSuccess: false,
  isCreateFailure: undefined,
  isReadRequest: false,
  isReadSuccess: false,
  isReadFailure: undefined,
  isReplyRequest: false,
  isReplySuccess: false,
  isReplyFailure: undefined,
  selected: undefined,
  draft: undefined,
};

export default (state = initial, action: AnyAction) => {
  switch (action.type) {
    case constants.LIST_REQUEST: {
      return { ...state, isListRequest: true, isListFailure: undefined };
    }
    case constants.LIST_SUCCESS: {
      const { item } = action;
      return {
        ...state,
        list: item,
        isListRequest: false,
        isListSuccess: true,
        isListFailure: undefined,
      };
    }
    case constants.LIST_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isListRequest: false,
        isListSuccess: false,
        isListFailure: error,
      };
    }
    case constants.CREATE_REQUEST: {
      return { ...state, isCreateRequest: true, isCreateFailure: undefined };
    }
    case constants.CREATE_SUCCESS: {
      const { item } = action;
      return {
        ...state,
        list: [item, ...(state?.list || [])],
        isCreateRequest: false,
        isCreateSuccess: true,
        isCreateFailure: undefined,
        draft: undefined,
      };
    }
    case constants.CREATE_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isCreateRequest: false,
        isCreateSuccess: false,
        isCreateFailure: error,
      };
    }

    case constants.REPLY_REQUEST: {
      return {
        ...state,
        isReplyFailure: undefined,
        isReplySuccess: false,
        isReplyRequest: true,
      };
    }
    case constants.REPLY_SUCCESS: {
      const { item: message } = action;
      const { threadId } = message;
      const t = state.list?.find((i) => i.id === threadId);
      if (!t) {
        return {
          ...state,
        };
      }
      const nt = { ...t, messages: [...(t.messages || []), message] };

      return {
        ...state,
        list: updateItemInArray(nt, state?.list || []),
        selected: nt,
        isReplyRequest: false,
        isReplySuccess: true,
        isReplyFailure: undefined,
      };
    }
    case constants.REPLY_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isReplyRequest: false,
        isReplySuccess: false,
        isReplyFailure: error,
      };
    }

    case constants.READ_REQUEST: {
      return { ...state, isReadRequest: true };
    }
    case constants.READ_SUCCESS: {
      const { item: thread } = action;

      return {
        ...state,
        list: updateItemInArray(thread, state.list || []),
        selected: thread,
        isReadRequest: false,
        isReadSuccess: true,
        isReadFailure: undefined,
      };
    }
    case constants.READ_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isReadRequest: false,
        isReadSuccess: false,
        isReadFailure: error,
      };
    }

    case constants.UPDATE_DRAFT: {
      return {
        ...state,
        draft: {
          ...state.draft,
          ...action.param,
        },
      };
    }

    default:
      return state;
  }
};
