import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ReduxStore } from 'customer/store/configureStore';
import { App, Client } from 'types/iam/App';
import service from './service';
import { NewAppRequest } from './types';

/* https://redux-toolkit.js.org/usage/usage-with-typescript#type-safety-with-extrareducers */

const list = createAsyncThunk(
  'IAM_APP_LIST',
  async (profileId: string, { getState, requestId }) => {
    const { listSlice } = (getState() as ReduxStore).iam.app;
    if (
      listSlice.status === 'request' &&
      requestId === listSlice.currentRequestId
    ) {
      return service.list(profileId);
    }
    // do nothing
    return Promise.resolve([]);
  },
);

const create = createAsyncThunk(
  'IAM_APP_CREATE',
  async (
    { profileId, data }: { profileId: string; data: NewAppRequest },
    { getState, requestId },
  ) => {
    const { createSlice } = (getState() as ReduxStore).iam.app;
    if (
      createSlice.status === 'request' &&
      requestId === createSlice.currentRequestId
    ) {
      return service.create(profileId, data);
    }
    // do nothing
    return Promise.resolve({} as App);
  },
);

const update = createAsyncThunk(
  'IAM_APP_UPDATE',
  async (
    { appId, data }: { appId: string; data: Partial<App> },
    { getState, requestId },
  ) => {
    const { updateSlice } = (getState() as ReduxStore).iam.app;
    if (
      updateSlice.status === 'request' &&
      requestId === updateSlice.currentRequestId
    ) {
      return service.update(appId, data);
    }
    // do nothing
    return Promise.resolve({} as App);
  },
);

const updateClient = createAsyncThunk(
  'IAM_APP_CLIENT_UPDATE',
  async (
    { clientId, data }: { clientId: string; data: Client },
    { getState, requestId },
  ) => {
    const { updateClientSlice } = (getState() as ReduxStore).iam.app;
    if (
      updateClientSlice.status === 'request' &&
      requestId === updateClientSlice.currentRequestId
    ) {
      return service.updateClient(clientId, data);
    }
    // do nothing
    return Promise.resolve({} as Client);
  },
);

const remove = createAsyncThunk(
  'IAM_APP_REMOVE',
  async ({ appId }: { appId: string }, { getState, requestId }) => {
    const { removeSlice } = (getState() as ReduxStore).iam.app;
    if (
      removeSlice.status === 'request' &&
      requestId === removeSlice.currentRequestId
    ) {
      return service.remove(appId);
    }
    // do nothing
    return Promise.resolve({} as App);
  },
);

const resetSecret = createAsyncThunk(
  'IAM_APP_RESET_SECRET',
  async ({ clientId }: { clientId: string }, { getState, requestId }) => {
    const { secretSlice } = (getState() as ReduxStore).iam.app;
    if (
      secretSlice.status === 'request' &&
      requestId === secretSlice.currentRequestId
    ) {
      return service.resetSecret(clientId);
    }
    // do nothing
    return Promise.resolve({} as Client);
  },
);

export default {
  list,
  create,
  update,
  updateClient,
  remove,
  resetSecret,
};
