import moment from 'moment';
import { DateTime } from 'luxon';

export const human = (timestamp?: string | Date): string => {
  if (!timestamp || typeof timestamp !== 'string') return 'Invalid date';
  return moment(timestamp).format('YYYY-MM-DD - HH:mm:ss');
};

/**
 * Takes a raw timestamp (string, Date or DateTime) and outputs a formatted string with date and time.
 * The default format is YYYY-MM-DD - HH:mm:ss
 * The format differs if you are using luxon DateTime (https://moment.github.io/luxon/#/parsing?id=table-of-tokens),
 * or anything else that Moment will handle (https://momentjs.com/docs/#/parsing/string-format/).
 */
export const getDateTime = (
  timestamp?: string | Date | DateTime,
  format?: string,
): string => {
  if (timestamp instanceof DateTime) {
    const fmt = format || 'dd/MM/yyyy - HH:mm:ss';
    return timestamp.toFormat(fmt);
  }
  if (
    moment.isMoment(timestamp) ||
    timestamp instanceof Date ||
    typeof timestamp === 'string'
  ) {
    const fmt = format || 'DD/MM/YYYY - HH:mm:ss';
    return moment(timestamp).format(fmt);
  }
  return 'Invalid date';
};

/**
 * Takes a raw timestamp (string, Date or DateTime) and outputs a formatted string with date.
 * The default format is YYYY-MM-DD.
 * The format differs if you are using luxon DateTime (https://moment.github.io/luxon/#/parsing?id=table-of-tokens),
 * or anything else that Moment will handle (https://momentjs.com/docs/#/parsing/string-format/).
 */
export const getDate = (
  timestamp?: string | Date | DateTime,
  format?: string,
) => {
  if (timestamp instanceof DateTime) {
    const fmt = format || 'dd/MM/yyyy';
    return timestamp.toFormat(fmt);
  }

  if (
    moment.isMoment(timestamp) ||
    timestamp instanceof Date ||
    typeof timestamp === 'string'
  ) {
    const fmt = format || 'DD/MM/YYYY';
    return moment(timestamp).format(fmt);
  }

  return 'Invalid date';
};

export const fromNow = (timestamp: string | Date): string => {
  if (!timestamp || typeof timestamp !== 'string') return 'Invalid date';
  return moment(timestamp).fromNow();
};

export const getDaysSinceNow = (days: number) => DateTime.now().minus({ days });

/**
 * returns years between the two dates.
 * @param {valid ISO 8601 format string} startIso
 * @param {valid ISO 8601 format string} endIso
 * @returns number
 *
 * @example
 * getYearsDiff('2016-05-25T09:08:34.123+06:00', '2017-05-25T09:08:34.123+06:00')
 */
export const getYearsDiff = (
  startIso: string,
  endIso: string,
): number | void => {
  if (!startIso || !endIso) return undefined;
  try {
    const start = DateTime.fromISO(startIso);
    const end = DateTime.fromISO(endIso);

    const diffInYears = end.diff(start, 'years');
    const d = diffInYears.toObject();

    return Math.floor(d?.years || 0);
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

/**
 * returns true if "a" happened before "b" in time.
 * @param {valid ISO 8601 format string} a
 * @param {valid ISO 8601 format string} b
 * @returns boolean
 */
export const isBefore = <T>(a: T, b: T): boolean | undefined => {
  if (a instanceof DateTime && b instanceof DateTime) {
    return a <= b;
  }
  if (a instanceof Date && b instanceof Date) {
    return a.getTime() < b.getTime();
  }
  if (typeof a === 'string' && typeof b === 'string') {
    return DateTime.fromISO(a) < DateTime.fromISO(b);
  }
  return undefined;
};

/**
 * returns true if a=b or if "a" happened before "b" in time.
 * @param {valid ISO 8601 format string} a
 * @param {valid ISO 8601 format string} b
 * @returns boolean
 */
export const isEqualOrBefore = <T>(a: T, b: T): boolean | undefined => {
  if (a instanceof DateTime && b instanceof DateTime) {
    return a <= b;
  }
  if (a instanceof Date && b instanceof Date) {
    return a.getTime() <= b.getTime();
  }
  if (typeof a === 'string' && typeof b === 'string') {
    return DateTime.fromISO(a) <= DateTime.fromISO(b);
  }
  return undefined;
};

export const addDays = (timestamp: string, days: number): string =>
  moment(timestamp).add(days, 'days').format('YYYY-MM-DD');

export const subtractDays = (timestamp: string, days: number): string =>
  moment(timestamp).subtract(days, 'days').format('YYYY-MM-DD');

export const getDateTimeNow = (): DateTime => DateTime.now();
export const yesterday = (): DateTime => DateTime.now().plus({ days: -1 });
export const getDateNow = (): string => DateTime.now().toISODate();

/**
 * number of minutes that has passed since d
 * @param d DateTime
 * @returns number
 */
export const diffMinNow = (d: DateTime): number | undefined => {
  if (d instanceof DateTime) {
    const { minutes } = d.diff(DateTime.now(), 'minutes').toObject();
    return minutes;
  }
  return undefined;
};
