import { AnyAction } from 'redux';
import { updateItemInArray } from 'src/utils/array';
import { reducer as flowReducer } from './Auth/slice';
import {
  LIST_FAILURE,
  LIST_REQUEST,
  LIST_SUCCESS,
  READ_FAILURE,
  READ_REQUEST,
  READ_SUCCESS,
} from './constants';
import { PartnerReduxStore } from './types';

const initial: PartnerReduxStore = {
  isListRequest: false,
  isListSuccess: false,
  isListFailure: undefined,
  isReadRequest: false,
  isReadSuccess: false,
  isReadFailure: undefined,
  list: [],
  details: [],
  selected: undefined,
  flow: {
    step: 'init',
    stepInt: 0,
    queryParams: {},
    uri: '',
    emoneyAccount: undefined,
  },
};

export default (state = initial, action: AnyAction) => {
  switch (action.type) {
    case LIST_REQUEST: {
      return { ...state, isListRequest: true, isListFailure: undefined };
    }
    case LIST_SUCCESS: {
      const { partners } = action;
      return {
        ...state,
        list: partners,
        isListRequest: false,
        isListSuccess: true,
        isListFailure: undefined,
      };
    }
    case LIST_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isListRequest: false,
        isListSuccess: false,
        isListFailure: error,
      };
    }

    case READ_REQUEST: {
      const { partnerId } = action;
      const selected =
        state?.details?.find((p) => p.id === partnerId) ||
        state?.list?.find((p) => p.id === partnerId) ||
        undefined;

      return { ...state, selected, isReadRequest: true };
    }
    case READ_SUCCESS: {
      const { partner } = action;
      return {
        ...state,
        selected: partner,
        details: updateItemInArray(partner, state.details),
        list: updateItemInArray(partner, state.list),
        isReadRequest: false,
        isReadSuccess: true,
        isReadFailure: undefined,
      };
    }
    case READ_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isReadRequest: false,
        isReadSuccess: false,
        isReadFailure: error,
      };
    }

    default:
      return { ...state, flow: flowReducer(state.flow, action) };
  }
};
