/* eslint-disable no-param-reassign */ // recommended by redux-toolkit to work with immer.
import { createSlice } from '@reduxjs/toolkit';
import { ValidationErrors } from 'types/validationTypes';
import { list } from './thunks';
import { EmoneyTokenReduxStore } from './types';

const initialState: EmoneyTokenReduxStore = {
  list: [],
  status: 'idle',
  currentRequestId: '',
};

/*
 * https://redux-toolkit.js.org/usage/usage-with-typescript
 */

export const { actions, reducer } = createSlice({
  name: 'tokens',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(list.pending, (state, action) => {
        // both `state` and `action` are now correctly typed
        // based on the slice state and the `pending` action creator

        state.status = 'request';
        state.currentRequestId = action.meta.requestId;
        state.error = undefined;
      })
      .addCase(list.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.status === 'request' &&
          state.currentRequestId === requestId
        ) {
          state.status = 'failed';
          state.error = action.error as ValidationErrors;
          state.currentRequestId = '';
        }
      })
      .addCase(list.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.status === 'request' &&
          state.currentRequestId === requestId
        ) {
          state.error = undefined;
          state.status = 'success';
          state.list = action.payload;
          state.currentRequestId = '';
        }
      });
  },
});

export default reducer;
