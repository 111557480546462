/**
 * Creates a wrapper function around the HTML5 Fetch API that provides
 * default arguments to fetch(...) and is intended to reduce the amount
 * of boilerplate code in the application.
 * https://developer.mozilla.org/docs/Web/API/Fetch_API/Using_Fetch
 */

function createFetch(fetch, { baseUrl, cookie }) {
  const defaults = {
    method: 'POST', // handy with API backends
    mode: baseUrl ? 'cors' : 'same-origin',
    credentials: baseUrl && !window?.App?.isProxy ? 'include' : 'same-origin',
  };

  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(cookie ? { Cookie: cookie } : null),
  };

  return async (url, allOptions) => {
    const { skipHeaders, ...options } = allOptions;
    const data = {
      ...defaults,
      ...options,
    };
    if (!skipHeaders) {
      data.headers = {
        ...defaultHeaders,
        ...(options?.headers || {}),
      };
    }

    if (url.startsWith('/api') && window?.App?.isProxy) {
      return fetch(url, options);
    }

    return url.startsWith('/api')
      ? fetch(`${baseUrl}${url}`, data)
      : fetch(url, options);
  };
}

export default createFetch;
