import { Route } from 'universal-router';
import { AppRouteResult } from '../types';

// The top-level (parent) route
const routes: Route<AppRouteResult> = {
  path: '',

  // Keep in mind, routes are evaluated in order
  children: [
    // The home route is added to client.js to make sure shared components are
    // added to client.js as well and not repeated in individual each route chunk.
    // {
    //   path: '',
    //   action: () => import(/* webpackMode: 'eager' */ './home'),
    // },
    {
      path: '',
      action: () => {
        return { redirect: `/accounts` };
      },
    },
    {
      path: '/accounts',
      children: [
        {
          path: '',
          action: () => import(/* webpackChunkName: 'accounts' */ './accounts'),
        },
        {
          path: '/:id',
          action: () =>
            import(/* webpackChunkName: 'account-detail' */ './accounts/$id'),
        },
        {
          path: '/:id/:action',
          action: () =>
            import(/* webpackChunkName: 'account-detail' */ './accounts/$id'),
        },
      ],
    },
    {
      path: '/addresses',
      children: [
        {
          path: '',
          action: () => {
            return { redirect: `/accounts` };
          },
        },
        {
          path: '/link',
          children: [
            {
              path: '',
              action: () =>
                import(
                  /* webpackChunkName: 'address-link' */ './addresses/link'
                ),
            },
            {
              path: '/:id',
              action: () =>
                import(
                  /* webpackChunkName: 'address-link' */ './addresses/link'
                ),
            },
          ],
        },
        {
          path: '/:id',
          action: () =>
            import(/* webpackChunkName: 'address-detail' */ './addresses/$id'),
        },
        {
          path: '/:id/:chain',
          action: () =>
            import(/* webpackChunkName: 'address-detail' */ './addresses/$id'),
        },
      ],
    },
    {
      path: '/link',
      action: () => {
        return { redirect: `/addresses/link` };
      },
    },
    {
      path: '/link/:id',
      action: ({ params }) => {
        return { redirect: `/addresses/link/${params.id}` };
      },
    },
    {
      path: '/developers',
      children: [
        {
          path: '',
          action: () =>
            import(/* webpackChunkName: 'developers' */ './developers'),
        },
      ],
    },
    {
      path: '/maintenance',
      action: () =>
        import(/* webpackChunkName: 'maintenance' */ './maintenance'),
    },
    {
      path: '/welcome',
      action: () => import(/* webpackChunkName: 'welcome' */ './welcome'),
    },
    {
      path: '/login',
      action: () => import(/* webpackChunkName: 'login' */ './login'),
    },
    {
      path: '/logout',
      action: () => import(/* webpackChunkName: 'logout' */ './logout'),
    },
    {
      path: '/promotions/gnosispay',
      action: () =>
        import(/* webpackChunkName: 'promotions-gnosis' */ './promotions'),
    },
    {
      path: '/signup',
      action: () => import(/* webpackChunkName: 'signup' */ './signup'),
    },
    {
      path: '/help',
      children: [
        {
          path: '',
          action: () => import(/* webpackChunkName: 'help-support' */ './help'),
        },
        {
          path: '/thread/:id',
          action: () =>
            import(
              /* webpackChunkName: 'help-thread-detail' */ './help/thread/$id'
            ),
        },
      ],
    },
    {
      path: '/confirm',
      children: [
        {
          path: '',
          action: () =>
            import(/* webpackChunkName: 'iam-confirm' */ './confirm'),
        },
        {
          path: '/:token?',
          action: () =>
            import(/* webpackChunkName: 'iam-confirm' */ './confirm'),
        },
      ],
    },
    {
      path: '/partners',
      children: [
        {
          path: '/:partnerId/auth',
          action: () =>
            import(
              /* webpackChunkName: 'partners-auth' */ './partners/$id.auth'
            ),
        },
      ],
    },
    {
      path: '/invite',
      children: [
        {
          path: '',
          action: () => import(/* webpackChunkName: 'iam-invite' */ './invite'),
        },
        {
          path: '/:token?',
          action: () => import(/* webpackChunkName: 'iam-invite' */ './invite'),
        },
      ],
    },
    {
      path: '/invitecodes',
      children: [
        {
          path: '',
          action: () =>
            import(/* webpackChunkName: 'iam-invite-codes' */ './invite-codes'),
        },
      ],
    },
    {
      path: '/reset',
      children: [
        {
          path: '',
          action: () => import(/* webpackChunkName: 'iam-reset' */ './reset'),
        },
        {
          path: '/sent',
          action: () =>
            import(/* webpackChunkName: 'iam-reset-sent' */ './reset/sent'),
        },
        {
          path: '/:token?',
          action: () => import(/* webpackChunkName: 'iam-reset' */ './reset'),
        },
      ],
    },

    {
      path: '/profiles',
      children: [
        {
          path: '',
          action: () =>
            import(/* webpackChunkName: 'kyc-profile' */ './profiles/index'),
        },
        {
          path: '/:id/:action',
          action: () =>
            import(
              /* webpackChunkName: 'kyc-profile-detail' */ './profiles/$id'
            ),
        },
        {
          path: '/:id',
          action: () =>
            import(
              /* webpackChunkName: 'kyc-profile-detail' */ './profiles/$id'
            ),
        },
      ],
    },
    // mock bank
    {
      path: '/mockbank',
      children: [
        {
          path: '',
          action: () =>
            import(/* webpackChunkName: 'mockbank' */ './mockbank/index'),
        },
        {
          path: '/:iban?',
          action: () =>
            import(/* webpackChunkName: 'mockbank' */ './mockbank/index'),
        },
      ],
    },
    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      path: '(.*)',
      action: () => import(/* webpackChunkName: 'not-found' */ './not-found'),
    },
  ],

  async action({ next }) {
    // Execute each child route until one of them return the result
    const route = await next();

    // Provide default values for title, description etc.
    route.title = `${route.title || 'Untitled Page'} | Monerium`;
    route.description = route.description || '';

    return route;
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes?.children?.unshift({
    path: '/error',
    action: () => import(/* webpackChunkName: 'error' */ './error'),
  });
}

export default routes;
