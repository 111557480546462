import rest from 'services/rest';
import { App, Client } from 'types/iam/App';
import { NewAppRequest } from './types';

export default {
  list: (profileId: string): Promise<App[]> =>
    rest.get(`/api/iam/profiles/${profileId}/apps`),
  create: (profileId: string, data: NewAppRequest): Promise<App> =>
    rest.post(`/api/iam/profiles/${profileId}/apps`, data),
  update: (appId: string, data: Partial<App>) =>
    rest.put(`/api/iam/apps/${appId}`, data),
  updateClient: (clientId: string, data: Partial<Client>) =>
    rest.put(`/api/iam/clients/${clientId}`, data),
  remove: (appId: string) => rest.del(`/api/iam/apps/${appId}`),
  resetSecret: (clientId: string): Promise<Client> =>
    rest.patch(`/api/iam/clients/${clientId}/secret`),
};
