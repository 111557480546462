import rest from 'services/rest';
import { AuthContext } from '../Login/types';
import { SetPasswordRequest, User } from './types';

/**
 * creates a user and sends an invite link to the user's email
 * @param {string} id Profile ID
 * @param {string} email Invited user email
 */
const invite = (id: string, email: string): Promise<User> =>
  rest.put(`/api/iam/profiles/${id}/invite`, {
    email,
  });

/**
 * Sets a password for an invited user
 * @param {string} token Authentication token from email
 * @param {string} password New password
 */
const setPassword = ({
  token,
  password,
}: SetPasswordRequest): Promise<AuthContext> =>
  rest.patch(`/api/iam/invite/${token}`, { password });

export default {
  invite,
  setPassword,
};
