/* eslint-disable no-param-reassign */ // recommended by redux-toolkit to work with immer.
import { createSlice } from '@reduxjs/toolkit';
import { ValidationErrors } from 'types/validationTypes';
import actions from './actions';
import { IamAppReduxStore } from './types';
import { splitAppsUris } from './util';

const initialState: IamAppReduxStore = {
  apps: [],
  selected: undefined,
  listSlice: {
    status: 'idle',
    currentRequestId: '',
  },
  createSlice: {
    status: 'idle',
    currentRequestId: '',
  },
  updateSlice: {
    status: 'idle',
    currentRequestId: '',
  },
  updateClientSlice: {
    status: 'idle',
    currentRequestId: '',
  },
  removeSlice: {
    status: 'idle',
    currentRequestId: '',
  },
  secretSlice: {
    status: 'idle',
    currentRequestId: '',
  },
};

/*
 * https://redux-toolkit.js.org/usage/usage-with-typescript
 */

const appsSlice = createSlice({
  name: 'apps',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(actions.list.pending, (state, action) => {
        state.listSlice.status = 'request';
        state.listSlice.currentRequestId = action.meta.requestId;
      })
      .addCase(actions.list.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.listSlice.status === 'request' &&
          state.listSlice.currentRequestId === requestId
        ) {
          state.listSlice.status = 'failed';
          state.listSlice.error = action.error as ValidationErrors;
          state.listSlice.currentRequestId = '';
        }
      })
      .addCase(actions.list.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.listSlice.status === 'request' &&
          state.listSlice.currentRequestId === requestId
        ) {
          state.listSlice.error = undefined;
          state.listSlice.status = 'success';
          state.apps = splitAppsUris(action.payload);
          state.listSlice.currentRequestId = '';
        }
      })
      .addCase(actions.create.pending, (state, action) => {
        state.createSlice.status = 'request';
        state.createSlice.currentRequestId = action.meta.requestId;
      })
      .addCase(actions.create.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.createSlice.status === 'request' &&
          state.createSlice.currentRequestId === requestId
        ) {
          state.createSlice.status = 'failed';
          state.createSlice.error = action.error as ValidationErrors;
          state.createSlice.currentRequestId = '';
        }
      })
      .addCase(actions.create.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.createSlice.status === 'request' &&
          state.createSlice.currentRequestId === requestId
        ) {
          state.createSlice.error = undefined;
          state.createSlice.status = 'success';
          state.createSlice.currentRequestId = '';
        }
      })
      .addCase(actions.update.pending, (state, action) => {
        state.updateSlice.status = 'request';
        state.updateSlice.currentRequestId = action.meta.requestId;
      })
      .addCase(actions.update.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.updateSlice.status === 'request' &&
          state.updateSlice.currentRequestId === requestId
        ) {
          state.updateSlice.status = 'failed';
          state.updateSlice.error = action.error as ValidationErrors;
          state.updateSlice.currentRequestId = '';
        }
      })
      .addCase(actions.update.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.updateSlice.status === 'request' &&
          state.updateSlice.currentRequestId === requestId
        ) {
          state.updateSlice.error = undefined;
          state.updateSlice.status = 'success';
          state.updateSlice.currentRequestId = '';
        }
      })
      .addCase(actions.updateClient.pending, (state, action) => {
        state.updateClientSlice.status = 'request';
        state.updateClientSlice.currentRequestId = action.meta.requestId;
      })
      .addCase(actions.updateClient.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.updateClientSlice.status === 'request' &&
          state.updateClientSlice.currentRequestId === requestId
        ) {
          state.updateClientSlice.status = 'failed';
          state.updateClientSlice.error = action.error as ValidationErrors;
          state.updateClientSlice.currentRequestId = '';
        }
      })
      .addCase(actions.updateClient.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.updateClientSlice.status === 'request' &&
          state.updateClientSlice.currentRequestId === requestId
        ) {
          state.updateClientSlice.error = undefined;
          state.updateClientSlice.status = 'success';
          state.updateClientSlice.currentRequestId = '';
        }
      })
      .addCase(actions.remove.pending, (state, action) => {
        state.removeSlice.status = 'request';
        state.removeSlice.currentRequestId = action.meta.requestId;
      })
      .addCase(actions.remove.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.removeSlice.status === 'request' &&
          state.removeSlice.currentRequestId === requestId
        ) {
          state.removeSlice.status = 'failed';
          state.removeSlice.error = action.error as ValidationErrors;
          state.removeSlice.currentRequestId = '';
        }
      })
      .addCase(actions.remove.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.removeSlice.status === 'request' &&
          state.removeSlice.currentRequestId === requestId
        ) {
          state.removeSlice.error = undefined;
          state.removeSlice.status = 'success';
          state.removeSlice.currentRequestId = '';
        }
      })
      .addCase(actions.resetSecret.pending, (state, action) => {
        state.secretSlice.status = 'request';
        state.secretSlice.currentRequestId = action.meta.requestId;
      })
      .addCase(actions.resetSecret.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.secretSlice.status === 'request' &&
          state.secretSlice.currentRequestId === requestId
        ) {
          state.secretSlice.status = 'failed';
          state.secretSlice.error = action.error as ValidationErrors;
          state.secretSlice.currentRequestId = '';
        }
      })
      .addCase(actions.resetSecret.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.secretSlice.status === 'request' &&
          state.secretSlice.currentRequestId === requestId
        ) {
          state.secretSlice.error = undefined;
          state.secretSlice.status = 'success';
          state.secretSlice.currentRequestId = '';
        }
      });
  },
});

export default appsSlice.reducer;
