import { createSlice } from '@reduxjs/toolkit';
import { Account } from '../../emoney/Account/types';
import { NormalizedQueryParams } from './types';
import { getStepInt } from './util';

const slice = createSlice({
  name: 'partner/flow',
  initialState: {
    step: 'init',
    stepInt: 0,
    queryParams: {} as NormalizedQueryParams,
    uri: '',
    emoneyAccount: {} as Account,
  },
  reducers: {
    goto(state, action) {
      state.step = action.payload;
      state.stepInt = getStepInt(action.payload);
    },
    setQueryParams(state, action) {
      state.queryParams = action.payload;
      state.uri = action.payload.redirectUri;
    },
    setUri(state, action) {
      state.uri = action.payload;
    },
    setEmoneyAccount(state, action) {
      state.emoneyAccount = action.payload;
    },
  },
});

export const { reducer } = slice;
export const { goto, setUri, setQueryParams, setEmoneyAccount } = slice.actions;
