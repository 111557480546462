import rest from 'services/rest';
import { Token } from 'src/types/emoney/Token';

export default {
  list: () =>
    rest.get('/api/emoney/tokens').then((tokens: Token[]) => {
      /**
       * Use this to test new chains
       */
      // tokens.push(
      //   {
      //     currency: 'eur',
      //     ticker: 'EUR',
      //     symbol: 'EURe',
      //     chain: 'gnosis',
      //     network: 'local',
      //     address: '0x905419e7a1f96a973ab8988ba19c84135c6f7122',
      //     decimals: 18,
      //   },
      //   {
      //     currency: 'gbp',
      //     ticker: 'GBP',
      //     symbol: 'GBPe',
      //     chain: 'gnosis',
      //     network: 'local',
      //     address: '0x204cdd1689c8a4da426894c150a0fa672a2eab4c',
      //     decimals: 18,
      //   },
      //   {
      //     currency: 'isk',
      //     ticker: 'ISK',
      //     symbol: 'ISKe',
      //     chain: 'gnosis',
      //     network: 'local',
      //     address: '0x2adaeaf11753515a37a3d50cecb636cf72ae4854',
      //     decimals: 18,
      //   },
      //   {
      //     currency: 'usd',
      //     ticker: 'USD',
      //     symbol: 'USDe',
      //     chain: 'gnosis',
      //     network: 'local',
      //     address: '0x45a2897792afa20b95f7dcaf5c0fb45b84daf439',
      //     decimals: 18,
      //   },
      // );

      return tokens.map((t: Token) => ({
        ...t,
        image: `https://monerium.app/tokens/${t.currency}/${t.currency}.png`,
      }));
    }),
};
