interface ItemWithId {
  id: string;
}
export const updateItemInArray = <I extends ItemWithId>(
  item?: I,
  arr: I[] = [],
): I[] => {
  if (!Array.isArray(arr)) {
    if (item?.id) return [item];
    return [];
  }
  if (!item?.id) {
    if (Array.isArray(arr)) return arr;
    return [];
  }
  const i = arr.findIndex((c) => c.id === item.id);
  if (i >= 0) return [...arr.slice(0, i), item, ...arr.slice(i + 1)];
  return [...arr, item];
};

export const removeItemFromArray = <I extends ItemWithId>(
  item?: ItemWithId,
  arr: I[] = [],
): I[] => {
  if (!Array.isArray(arr)) {
    return [];
  }
  if (!item?.id) {
    if (Array.isArray(arr)) return arr;
    return [];
  }
  return arr.filter((i) => i.id !== item.id);
};

export const updateHash = <I extends ItemWithId>(
  arr: I[],
  hash: Record<string, I> = {},
) => {
  return arr.reduce((h, i) => {
    if (!i || !i?.id) return h;
    return { ...h, [i.id]: i };
  }, hash);
};

export const hashToArray = <I extends ItemWithId>(
  hash: Record<string, I>,
): I[] => {
  return Object.keys(hash).map((k) => hash[k]);
};

/**
 * @param {Array} list an array of objects.
 * @param {int} key which property to use to group together.
 */
export const groupByKey = <T extends Array<any>>(
  list: T,
  key: string,
): Record<string, T> =>
  list.reduce(
    (hash, obj) => ({
      ...hash,
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    }),
    {},
  );

export default {
  updateItemInArray,
  updateHash,
  hashToArray,
  groupByKey,
};
