export default {
  TEST_REQUEST: 'COOKIE_NOTICE_TEST_REQUEST',
  TEST_SUCCESS: 'COOKIE_NOTICE_TEST_SUCCESS',
  TEST_FAILURE: 'COOKIE_NOTICE_TEST_FAILURE',

  GET_REQUEST: 'COOKIE_NOTICE_GET_REQUEST',
  GET_SUCCESS: 'COOKIE_NOTICE_GET_SUCCESS',
  GET_FAILURE: 'COOKIE_NOTICE_GET_FAILURE',

  ACCEPT_REQUEST: 'COOKIE_NOTICE_ACCEPT_REQUEST',
  ACCEPT_SUCCESS: 'COOKIE_NOTICE_ACCEPT_SUCCESS',
  ACCEPT_FAILURE: 'COOKIE_NOTICE_ACCEPT_FAILURE',
};
