import { AnyAction } from 'redux';
import constants from './constants';
import { IamResetReduxStore } from './types';

const initial: IamResetReduxStore = {
  isResetRequest: false,
  isResetSuccess: false,
  isResetFailure: undefined,
  isSendRequest: false,
  isSendSuccess: false,
  isSendFailure: undefined,
};

export default (state = initial, action: AnyAction) => {
  switch (action.type) {
    case constants.RESET_REQUEST: {
      return { ...state, isResetRequest: true, isResetFailure: undefined };
    }
    case constants.RESET_SUCCESS: {
      return {
        ...state,
        isResetRequest: false,
        isResetSuccess: true,
        isResetFailure: undefined,
      };
    }
    case constants.RESET_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isResetRequest: false,
        isResetSuccess: false,
        isResetFailure: error,
      };
    }
    case constants.SEND_REQUEST: {
      return { ...state, isSendRequest: true, isSendFailure: undefined };
    }

    case constants.SEND_SUCCESS: {
      return {
        ...state,
        isSendRequest: false,
        isSendSuccess: true,
        isSendFailure: undefined,
      };
    }
    case constants.SEND_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isSendRequest: false,
        isSendSuccess: false,
        isSendFailure: error,
      };
    }
    default:
      return state;
  }
};
