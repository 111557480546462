export default {
  ORDER_REQUEST: 'EMONEY_TX_ORDER_REQUEST',
  ORDER_SUCCESS: 'EMONEY_TX_ORDER_SUCCESS',
  ORDER_FAILURE: 'EMONEY_TX_ORDER_FAILURE',

  LIST_REQUEST: 'EMONEY_TX_LIST_REQUEST',
  LIST_SUCCESS: 'EMONEY_TX_LIST_SUCCESS',
  LIST_FAILURE: 'EMONEY_TX_LIST_FAILURE',

  CREATE_REQUEST: 'EMONEY_TX_CREATE_REQUEST',
  CREATE_SUCCESS: 'EMONEY_TX_CREATE_SUCCESS',
  CREATE_FAILURE: 'EMONEY_TX_CREATE_FAILURE',
};
