'use client';

import CosmosProvider from 'components/Wallet/cosmos/Provider';
import EvmProvider from 'components/Wallet/evm/Provider';
import React from 'react';

const WalletConnector = ({ children }: { children: React.ReactNode }) => {
  return (
    <EvmProvider>
      <CosmosProvider>{children}</CosmosProvider>
    </EvmProvider>
  );
};
export default WalletConnector;
