export const CONFIRM_SUCCESS = 'IAM_CONFIRM_SUCCESS';
export const CONFIRM_REQUEST = 'IAM_CONFIRM_REQUEST';
export const CONFIRM_FAILURE = 'IAM_CONFIRM_FAILURE';
export const RESEND_REQUEST = 'IAM_RESEND_REQUEST';
export const RESEND_SUCCESS = 'IAM_RESEND_SUCCESS';
export const RESEND_FAILURE = 'IAM_RESEND_FAILURE';
export default {
  CONFIRM_REQUEST,
  CONFIRM_SUCCESS,
  CONFIRM_FAILURE,
  RESEND_REQUEST,
  RESEND_SUCCESS,
  RESEND_FAILURE,
};
