import {
  AccountData,
  ChainInfo,
  Keplr,
  OfflineDirectSigner,
} from '@keplr-wallet/types';
import { useChains } from 'components/Chain/hooks';
import cosmosChains from 'components/Wallet/cosmos/chains';
import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { CosmosChainId } from 'types/emoney/Chain/indexV2';

function uint8ArrayToHex(uint8Array: Uint8Array): string {
  return Buffer.from(uint8Array).toString('hex');
}

export interface CosmosProviderContextType {
  isInstalled: boolean;
  isConnected: boolean;
  connect: () => Promise<void>;
  disconnect: () => void;
  getAddresses: () => Promise<string[]>;
  signMessage: (
    message: string,
  ) => Promise<{ signature: string; publicKey: string }>;
  address: string;
  chainId: CosmosChainId;
  provider: Keplr | undefined;
  signer: OfflineDirectSigner | undefined;
}

export const CosmosProviderContext = createContext<
  CosmosProviderContextType | undefined
>(undefined);

interface CosmosProviderProviderProps {
  children: ReactNode;
}

export const CosmosContextProvider = ({
  children,
}: CosmosProviderProviderProps) => {
  const [isInstalled, setIsInstalled] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [address, setAddress] = useState<string>('');
  const [provider, setProvider] = useState<Keplr | undefined>(undefined);
  const [signer, setSigner] = useState<OfflineDirectSigner | undefined>(
    undefined,
  );

  const chains = useChains();
  const nobleChain = chains.find((c) => c.kind === 'cosmos');
  const chainId = nobleChain?.chainId as CosmosChainId;

  useEffect(() => {
    setProviderAndSigner();
  }, []);

  const setProviderAndSigner = useCallback((): Keplr | undefined => {
    if (!provider) {
      const { keplr } = window;
      if (keplr) {
        const offlineSigner = keplr.getOfflineSigner(
          chainId,
        ) as OfflineDirectSigner;

        // Listen for account changes.
        window.addEventListener('keplr_keystorechange', async () => {
          const accounts = await offlineSigner.getAccounts();
          setAddress(accounts[0].address);
        });

        setIsInstalled(true);
        setProvider(keplr);
        setSigner(offlineSigner);
        return keplr;
      }
    }

    return provider;
  }, [provider, signer, chainId]);

  const connect = async () => {
    try {
      await enable(chainId);
      await getAddress();

      const connection = await provider?.getKey(chainId);

      setIsConnected(!!connection);
    } catch (error) {
      throw new Error((error as Error)?.message);
    }
  };

  const disconnect = () => {
    setIsConnected(false);
    setAddress('');

    if (provider && provider.disable) {
      provider.disable();
    }
  };

  const getAddress = async () => {
    const fetchedAddresses = await getAddresses();
    setAddress(fetchedAddresses[0]);
  };

  const getAddresses = async (): Promise<string[]> => {
    if (signer) {
      const accounts: readonly AccountData[] = await signer.getAccounts();
      return accounts.map((account) => account.address);
    }
    return [];
  };

  const signMessage = async (message: string) => {
    const address = (await getAddresses())[0];
    const sig = await provider?.signArbitrary(chainId, address, message);

    // Verify signature
    if (sig?.signature && sig?.pub_key?.value) {
      const signatureArray = new Uint8Array(
        Buffer.from(sig.signature, 'base64'),
      );
      const publicKeyArray = new Uint8Array(
        Buffer.from(sig.pub_key.value, 'base64'),
      );

      const signature = `0x${uint8ArrayToHex(signatureArray)}`;
      const publicKey = `0x${uint8ArrayToHex(publicKeyArray)}`;
      return { signature, publicKey };
    }
    throw new Error('Unable to produce signature in Keplr');
  };

  const enable = async (chainId: CosmosChainId): Promise<void> => {
    const chainInfo = cosmosChains.find(
      (c) => c.chainId === chainId,
    ) as ChainInfo;

    try {
      await provider?.experimentalSuggestChain(chainInfo);
    } catch (error) {
      throw new Error((error as Error)?.message);
    }
    try {
      await provider?.enable(chainId);
    } catch (error) {
      throw new Error((error as Error)?.message);
    }
  };

  return (
    <CosmosProviderContext.Provider
      value={{
        isInstalled,
        isConnected,
        connect,
        disconnect,
        getAddresses,
        signMessage,
        address,
        chainId,
        provider,
        signer,
      }}
    >
      {children}
    </CosmosProviderContext.Provider>
  );
};
export default CosmosContextProvider;
