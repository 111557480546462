import { ValidationErrors } from 'types/validationTypes';

type ErrorResponseType = {
  status: string;
  code: number;
  message: string;
  errors?: ValidationErrors;
  details?: ValidationErrors;
  errorId?: string;
};

export const ALERT_ERROR_UNKNOWN = 'Sorry! Something went wrong.';
export const ALERT_ERROR_CONNECTION_REFUSED =
  'Could not connect with server, please try reloading the page.';

export default class ValidationError extends Error {
  status: string;

  code: number;

  errors?: ValidationErrors;

  details?: ValidationErrors;

  errorId?: string;

  static getMessage = (message: string) => {
    if (!message) {
      return ALERT_ERROR_CONNECTION_REFUSED;
    }
    switch (message?.toLowerCase()) {
      case 'failed to fetch':
        return ALERT_ERROR_CONNECTION_REFUSED;
      default:
        return message || '';
    }
  };

  // Create a new ValidationError with unknown error message
  static unknown = () => {
    return new ValidationError({
      status: 'Internal Server Error',
      code: 500,
      message: ALERT_ERROR_UNKNOWN,
    });
  };

  static connectionRefused = () => {
    return new ValidationError({
      status: 'Service Unavailable',
      code: 503,
      message: ALERT_ERROR_CONNECTION_REFUSED,
    });
  };

  // Create new ValidationError from errors object
  static parseErrors = (errors: ValidationErrors) => {
    return new ValidationError({
      status: 'Validation error',
      code: 400,
      message: 'Validation errors',
      errors,
    });
  };

  constructor(er: ErrorResponseType) {
    super(ValidationError.getMessage(er.message));
    this.status = er.status;
    this.code = er.code;
    this.errors = er.errors;
    this.details = er.details;
    this.errorId = er.errorId || '';

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ValidationError.prototype);
  }

  // Removes all errors except those who have a key in the keys array
  filterErrors(keys: string[]): ValidationErrors {
    if (!Array.isArray(keys) || !keys.length) {
      return {};
    }
    const errors = this.errors || {};
    const errorKeys = Object.keys(errors);
    if (!errorKeys.length) return {};

    const f = errorKeys.filter((e) => keys.find((i) => e === i));

    return f.reduce((e, i) => {
      return {
        ...e,
        [i]: errors[i],
      };
    }, {});
  }
}
