import { AnyAction } from 'redux';
import constants from 'src/customer/components/emoney/Address/constants';
import { AppThunk } from 'src/customer/store/configureStore';
import ValidationError from 'src/utils/error';
import { ValidationErrors } from 'types/validationTypes';
import { v4 as uuidV4 } from 'uuid';
import { ALERT_ADD, ALERT_CLOSE, ALERT_ERROR_UNKNOWN } from './constants';
import { Variant } from './types';
import { stringifyErrors } from './utils';

/**
 * @deprecated Use src/components/Notification
 */
const add =
  ({ variant, message }: { variant: Variant; message: string }): AppThunk =>
  (dispatch) => {
    dispatch({
      type: ALERT_ADD,
      alert: { key: uuidV4(), variant, message, dismissed: false },
    });
  };

const error =
  (message: string): AppThunk =>
  (dispatch) => {
    dispatch(add({ message, variant: 'error' }));
  };

const unknownError = (): AppThunk => (dispatch) => {
  dispatch(error(ALERT_ERROR_UNKNOWN));
};

const close =
  (key: string): AppThunk =>
  (dispatch) => {
    dispatch({ type: ALERT_CLOSE, key });
  };

/**
 * @deprecated Use src/components/Notification
 */
export const alertFailure =
  (
    err?: ValidationError | unknown,
    failureAction?: (e?: ValidationErrors) => AnyAction,
  ): AppThunk =>
  (dispatch) => {
    if (err instanceof ValidationError && err) {
      if (err?.errors && failureAction) {
        // Check for common errors
        if (
          err.errors?.address === constants.LINK_ERROR_ANOTHER ||
          err.errors?.address === constants.LINK_ERROR_YOURS
        ) {
          dispatch(error(err.errors?.address));
        } else {
          dispatch(error(stringifyErrors(err.errors)));
        }

        dispatch(failureAction(err.errors));
        return;
      }
      if (err?.details && failureAction) {
        dispatch(error(stringifyErrors(err.details)));
        dispatch(failureAction(err.details));
        return;
      }
      if (err?.message) {
        dispatch(error(err.message));
        if (failureAction) dispatch(failureAction({ common: err.message }));
        return;
      }
    }

    dispatch(unknownError());
    if (failureAction) dispatch(failureAction());
  };

export default {
  /**
   * @deprecated Use src/components/Notification
   */
  close,
  /**
   * @deprecated Use src/components/Notification
   */
  failure: alertFailure,
};
