'use client';

import { useChains } from 'components/Chain/hooks';
import { useSharedAppContext } from 'providers/AppConfig';
import React from 'react';
import { WagmiProvider, cookieToInitialState } from 'wagmi';
import evmChains from './chains';
import { config as getWagmiConfig } from './config';
import { ViemChains } from './types';

const EvmProvider = ({ children }: { children: React.ReactNode }) => {
  const { environment, cookies } = useSharedAppContext();
  const chains = useChains('evm');

  const wagmiChains = evmChains.filter((evmChain) =>
    chains.some((chain) => chain.chainId === evmChain.id),
  ) as unknown as ViemChains;

  const wagmiConfig = getWagmiConfig(environment, wagmiChains);
  const wagmiInitialState = cookieToInitialState(wagmiConfig, cookies);

  return (
    <WagmiProvider
      config={wagmiConfig}
      initialState={wagmiInitialState}
      reconnectOnMount
    >
      {children}
    </WagmiProvider>
  );
};
export default EvmProvider;
