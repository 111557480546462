import { AnyAction } from 'redux';
import constants, { SIGNUP_SUCCESS } from './constants';
import { IamSignupReduxStore } from './types';

const initial: IamSignupReduxStore = {
  isSignupRequest: false,
  isSignupSuccess: false,
  isSignupFailure: undefined,
};

export default (state = initial, action: AnyAction) => {
  switch (action.type) {
    case constants.SIGNUP_REQUEST: {
      return { ...state, isSignupRequest: true, isSignupFailure: undefined };
    }
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        isSignupRequest: false,
        isSignupSuccess: true,
        isSignupFailure: undefined,
      };
    }
    case constants.SIGNUP_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isSignupRequest: false,
        isSignupSuccess: false,
        isSignupFailure: error,
      };
    }
    default:
      return state;
  }
};
