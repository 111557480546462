import { combineReducers } from 'redux';
import drawer from './Drawer/slice';

/**
 * When `account` has been refactored to use redux-toolkit, we won't need to define the type for combineReducers anymore
 */

export default combineReducers<{
  drawer: { right: { isOpen: boolean } };
}>({
  drawer,
});
