import useTokens from 'customer/components/emoney/Token/hooks';
import useLogin from 'customer/components/iam/Login/hooks';
import React, { useEffect } from 'react';

const TokenProvider = ({ children }: { children: React.ReactNode }) => {
  const { tokens, getTokens } = useTokens();
  const { isVerified } = useLogin();

  useEffect(() => {
    if (isVerified && tokens?.length === 0) {
      getTokens();
    }
  }, [tokens, isVerified]);

  return <>{children}</>;
};
export default TokenProvider;
