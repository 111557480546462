export default {
  LOGIN_REQUEST: 'IAM_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'IAM_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'IAM_LOGIN_FAILURE',

  LOGOUT_REQUEST: 'IAM_LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'IAM_LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'IAM_LOGOUT_FAILURE',

  READ_REQUEST: 'IAM_READ_REQUEST',
  READ_SUCCESS: 'IAM_READ_SUCCESS',
  READ_FAILURE: 'IAM_READ_FAILURE',
};
