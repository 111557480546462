import { AnyAction } from 'redux';
import {
  CREATE_VERSION_FAILURE,
  CREATE_VERSION_REQUEST,
  CREATE_VERSION_SUCCESS,
  READ_FAILURE,
  READ_REQUEST,
  READ_SUCCESS,
  STORE_FAILURE,
  STORE_REQUEST,
  STORE_SUCCESS,
} from './constants';
import { UserReduxStore } from './types';

const initial: UserReduxStore = {
  detail: undefined,
  isReadRequest: false,
  isReadSuccess: false,
  isReadFailure: undefined,
  isStoreRequest: false,
  isStoreSuccess: false,
  isStoreFailure: undefined,
  isCreateVersionRequest: false,
  isCreateVersionSuccess: false,
  isCreateVersionFailure: undefined,
  validationErrors: undefined,
};

export default (
  state: UserReduxStore = initial,
  action: AnyAction,
): UserReduxStore => {
  switch (action.type) {
    case READ_REQUEST: {
      return { ...state, isReadRequest: true };
    }
    case READ_SUCCESS: {
      const { item } = action;
      return {
        ...state,
        detail: item,
        isReadRequest: false,
        isReadSuccess: true,
        isReadFailure: undefined,
      };
    }
    case READ_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isReadRequest: false,
        isReadSuccess: false,
        isReadFailure: error,
      };
    }

    case STORE_REQUEST: {
      const { item, error } = action;
      return {
        ...state,
        detail: {
          ...state.detail,
          ...(item || {}),
        },
        isStoreRequest: true,
        validationErrors: error,
      };
    }
    case STORE_SUCCESS: {
      const { item } = action;

      return {
        ...state,
        detail: { ...(state.detail || {}), ...(item || {}) },
        isStoreRequest: false,
        isStoreSuccess: true,
        isStoreFailure: undefined,
      };
    }
    case STORE_FAILURE: {
      const { error } = action;
      const validationErrors = {
        ...(state.validationErrors || {}),
        ...(error || {}),
      };
      return {
        ...state,
        isStoreRequest: false,
        isStoreSuccess: false,
        isStoreFailure: error,
        validationErrors,
      };
    }

    case CREATE_VERSION_REQUEST: {
      return {
        ...state,
        isCreateVersionRequest: true,
      };
    }
    case CREATE_VERSION_SUCCESS: {
      const { item } = action;

      return {
        ...state,
        detail: item,
        isCreateVersionRequest: false,
        isCreateVersionSuccess: false,
        isCreateVersionFailure: undefined,
        validationErrors: undefined,
      };
    }
    case CREATE_VERSION_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isCreateVersionRequest: false,
        isCreateVersionSuccess: false,
        isCreateVersionFailure: error,
        validationErrors: error,
      };
    }
    default:
      return state;
  }
};
